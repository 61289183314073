import { inject } from '@angular/core';
import {
  provideRouter,
  // withDebugTracing,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withNavigationErrorHandler,
  withPreloading,
  NavigationError,
  PreloadAllModules,
  RedirectCommand,
  Router,
  Routes,
} from '@angular/router';

import { isAuthenticated } from './core/guards/is-authenticated/is-authenticated.guard';
import { isEnabled } from './core/guards/is-enabled/is-enabled.guard';
import { isUnauthenticated } from './core/guards/is-unauthenticated/is-unauthenticated.guard';
import { MDB_LOGGER_FACTORY } from './core/observability/provider';
import { AuthStatusType } from './services/auth/auth-state.service';
import { MDB_AUTH_STATUS } from './services/auth/tokens';
import { VirtualSite } from './services/virtual-site/virtual-site';

export function buildRouterConfiguration() {
  return provideRouter(
    ROUTES,
    withEnabledBlockingInitialNavigation(),
    // withDebugTracing(),
    withInMemoryScrolling({
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    withNavigationErrorHandler(navigationErrorHandler),
    withPreloading(PreloadAllModules)
  );
}

function navigationErrorHandler(
  event: NavigationError
): unknown | RedirectCommand {
  const logger = inject(MDB_LOGGER_FACTORY)('AppRouter');
  const router = inject(Router);

  logger.error(
    'Navigation Error',
    {
      id: event.id,
      type: event.type,
      url: event.url,
      target: event.target,
    },
    event.error
  );

  return new RedirectCommand(router.parseUrl(''));
}

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: () => {
      if (inject(MDB_AUTH_STATUS)() === AuthStatusType.Anonymous) {
        return 'login';
      }

      return 'app/home';
    },
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./login/login.component').then(m => m.LoginComponent),
    canActivate: [isUnauthenticated],
    children: [
      {
        path: 'account-lookup',
        loadComponent: () =>
          import('./account-lookup/account-lookup.component').then(
            m => m.AccountLookupComponent
          ),
        canActivate: [
          isEnabled((site: VirtualSite) => site.settings.enableAccountLookup),
        ],
      },
      {
        path: 'billing-group-selection',
        loadComponent: () =>
          import('./billing-group-modal/billing-group-modal.component').then(
            m => m.BillingGroupModalComponent
          ),
        outlet: 'modal',
      },
    ],
  },
  {
    path: 'app',
    canActivate: [isAuthenticated],
    loadComponent: () =>
      import('./dashboard/dashboard.component').then(m => m.DashboardComponent),
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./home/home.component').then(m => m.HomeComponent),
      },
      {
        path: 'bill-pay',
        loadComponent: () =>
          import('./payments/payment/payment.component').then(
            m => m.PaymentComponent
          ),
      },
      {
        path: 'demographics',
        loadComponent: () =>
          import('./demographics/demographics/demographics.component').then(
            m => m.DemographicsComponent
          ),
      },
      {
        path: 'payment-plan',
        loadComponent: () =>
          import('./payments/payment-plan/payment-plan.component').then(
            m => m.PaymentPlanComponent
          ),
      },
      {
        path: 'surveyexp',
        loadComponent: () =>
          import('./payments/payment-survey/payment-survey.component').then(
            m => m.PaymentSurveyComponent
          ),
      },
      {
        path: 'insurance',
        loadComponent: () =>
          import('./insurance/insurance-wrapper.component').then(
            m => m.InsuranceWrapperComponent
          ),
        children: [],
      },
    ],
  },
  {
    path: 'support',
    loadComponent: () =>
      import('./support/support.component').then(m => m.SupportComponent),
  },
  {
    path: 'feedback',
    loadComponent: () =>
      import('./feedback/feedback.component').then(m => m.FeedbackComponent),
  },
  {
    path: 'contact',
    loadComponent: () =>
      import('./contact-us/contact-us/contact-us.component').then(
        m => m.ContactUsComponent
      ),
  },
  {
    path: 'pre-pay',
    loadComponent: () =>
      import('./pre-pay/pre-pay-search/pre-pay-search.component').then(
        m => m.PrePaySearchComponent
      ),
  },
  { path: '**', redirectTo: '/app/home', pathMatch: 'full' },
];
