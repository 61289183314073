import {
  computed,
  inject,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  output,
  OnDestroy,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { BannerComponent } from '../communication-banner/banner.component';
import { AuthStatusType } from '../services/auth/auth-state.service';
import { AuthService } from '../services/auth/auth.service';
import { MDB_AUTH_STATUS } from '../services/auth/tokens';
import { PaperlessBillingService } from '../services/paperless-billing/paperless-billing.service';
import { VirtualSiteType } from '../services/virtual-site/virtual-site.models';
import { MDB_VIRTUAL_SITE } from '../services/virtual-site/virtual-site.service';
import { SvgComponent } from '../svg/svg.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
  imports: [RouterModule, SvgComponent],
  providers: [BannerComponent],
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  public logout = output<void>();

  private authService = inject(AuthService);
  private authStatus = inject(MDB_AUTH_STATUS);
  bannerComponent = inject(BannerComponent);
  paperlessBillingService = inject(PaperlessBillingService);
  site = inject(MDB_VIRTUAL_SITE);

  @ViewChild('NavItems') navItems!: ElementRef<HTMLUListElement>;
  @ViewChild('NavButton') navButton!: ElementRef<HTMLButtonElement>;

  typeGlobal = VirtualSiteType.Global;

  protected isAnonymous = computed(() => {
    return this.authStatus() === AuthStatusType.Anonymous;
  });

  protected isAuthenticated = computed(() => {
    return this.authStatus() === AuthStatusType.Authenticated;
  });

  async onLogoutClick() {
    await this.authService.logout('User Logout');
  }

  ngAfterViewInit() {
    if (this.navItems.nativeElement.classList.contains('is-active')) {
      this.navButton.nativeElement.setAttribute('aria-expanded', 'true');
    }

    this.navItems.nativeElement.addEventListener(
      'click',
      this.handleMenuLinkClick.bind(this)
    );
  }

  handleMenuLinkClick(event: Event) {
    const eventTarget = event.target as HTMLElement;
    if (eventTarget.tagName === 'A') {
      this.showHideNav();
    }
  }

  menuButtonClickHandler() {
    this.showHideNav();
  }

  showHideNav() {
    if (this.navItems.nativeElement.classList.contains('is-active')) {
      this.navButton.nativeElement.setAttribute('aria-expanded', 'false');
      this.navItems.nativeElement.classList.remove('is-active');
    } else {
      this.navItems.nativeElement.classList.add('is-active');
      this.navButton.nativeElement.setAttribute('aria-expanded', 'true');
    }
  }

  ngOnDestroy() {
    this.navItems.nativeElement.removeEventListener(
      'click',
      this.handleMenuLinkClick.bind(this)
    );
  }
}
